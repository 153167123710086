const numberFormat = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

export default function formatPrice(
  price: number | Sproutl.Money | undefined,
): string {
  if (!price) {
    return '';
  }

  if (price === 0 || (typeof price === 'object' && price?.centAmount === 0)) {
    return 'Free';
  }

  if (typeof price === 'number') {
    return numberFormat.format(price / 100);
  }

  return numberFormat.format(price.centAmount / 100);
}
