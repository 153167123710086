import styled from 'styled-components';

export const Frame = styled.div`
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  bottom: -2px;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
`;

export const VideoElement = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
